'use client';

import useRenderCount from '@/hooks/use-render-count';

export default function RenderCounter({ layoutName = 'Root Layout' }) {
  const renderCount = useRenderCount();
  console.log(`[${layoutName}] Render count: ${renderCount}`);

  return null;
}

'use client';

import { ProfilerOnRenderCallback, useEffect, useRef } from 'react';

export default function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current += 1;
  });
  return count.current;
}

export const onRenderCallback: ProfilerOnRenderCallback = (
  id,
  phase,
  actualDuration,
  baseDuration,
  startTime,
  commitTime,
) => {
  console.log(`${id} ${phase} phase with duration ${actualDuration}ms`);
  console.log(`Base duration: ${baseDuration}ms`);
  console.log(`Start time: ${startTime}ms`);
  console.log(`Commit time: ${commitTime}ms`);
};

'use client';

import { usePathname } from 'next/navigation';
import Button from '../molecules/button';
import { useAppState } from '../providers/state-provider';

export default function RenewButton() {
  const {
    state: { searchParams },
  } = useAppState();
  const pathname = usePathname();
  const url = new URL(`https://null${pathname}?${searchParams}`);

  return (
    <Button
      className='mr-4 hidden h-9 align-text-bottom text-lg lg:inline-block'
      tagType='a'
      buttonType='red'
      href='/my-account?tab=renew'
      onClick={() =>
        localStorage.setItem('renewRedirect', `${url.pathname}${url.search}`)
      }
    >
      Renew Now
    </Button>
  );
}

'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Button from '../molecules/button';
import { useAppState } from '../providers/state-provider';

export default function LoginButton() {
  const {
    state: { searchParams },
  } = useAppState();
  const pathname = usePathname();

  const url = new URL(`https://null${pathname}?${searchParams}`);
  return (
    <Link
      href='/user/login'
      onClick={() =>
        localStorage.setItem('referrer', `${url.pathname}${url.search}`)
      }
    >
      <Button className='h-9 align-text-bottom text-lg lg:inline-block'>
        Login
      </Button>
    </Link>
  );
}

import { InlineWidget } from 'react-calendly';
import Dialog from './dialog';

export default function CalendlyDialog({
  calendlyIsOpen,
  setCalendlyIsOpen,
}: {
  calendlyIsOpen: boolean;
  setCalendlyIsOpen: (isOpen: boolean) => void;
}) {
  return (
    <Dialog
      className='!max-w-5xl overflow-hidden rounded bg-white'
      isOpen={calendlyIsOpen}
      onBackdropClick={() => setCalendlyIsOpen(false)}
    >
      {calendlyIsOpen && (
        <InlineWidget
          url='https://calendly.com/brettcblair/sermons-com-training'
          styles={{ height: '690px' }}
        />
      )}
    </Dialog>
  );
}
